import React from 'react'
import "./forgot.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'


const Success = () => {
  return (
    <>
    <div className="forgotpassword-wrapper">
    <Navbar />
      <section className="forgot-password">
        <div className="custom-container">
            <div className="parent-card">
                {/* <div className="upper-logo">
                    <img src="\assets\forgot\forgot-logo.svg" alt="img" className='img-fluid' />
                </div> */}
                <div className="bottom-content">
                    {/* <img src="\assets\forgot\happyface.svg" alt="img" className='img-fluid' /> */}
                    <h6>Success!</h6>
                    <p>Your Password has been successfuly changed</p>
                   <Link className='w-100' to="/"> <button className='btn-send'>Login</button></Link>
                </div>
            </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default Success
