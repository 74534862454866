import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion, Dropdown, Modal } from "react-bootstrap";
import { Api_Url } from "../../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import moment from "moment";

const DataTable = ({
  getPrevData,
  getNextData,
  currentPage,
  pages,
  allRounds,
  GetAllRounds,
  setShow111,
  setUserDel,
  setShow11,
  handleShow33,
  handleShow44,

  setShow22,
  seteditval,
  roundTokenData,
  roundTokenDataInstitution,
  ownerAddress,
  roundTokens,
  roundPrice,
  round,
}) => {
  function convertDateFormat(input) {
    // const createDate = moment(input).format("DD-MM-YYYY HH:mm");
    // console.log("return vslue",createDate, input.toisoSt)
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }
  const handliclick = (elem) => {
    seteditval(elem);
    setShow11(true);
  };

  const handleClickRound = (elem) => {
    seteditval(elem);

    roundTokenData(elem?.newRound);
    handleShow33();
  };

  const handleClickRoundInstitution = (elem) => {
    seteditval(elem);

    roundTokenDataInstitution();
    handleShow44();
  };

  const { account } = useWeb3React();

  // console.log("accoumt we have", account)
  const connectwalletcond = () => {
    toast.error("Please connect your wallet first ", {
      position: "top-right",
      autoClose: 3000,
    });
  };
  const ownerWalletAddressMessage = () => {
    toast.error("This Wallet Address is not authorized for this", {
      position: "top-right",
      autoClose: 3000,
    });
  };

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Round No{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Sale Price{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Starts on{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Ends on{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                {/* <th>
                  <div className="parent-tag">
                    Total Allocation <div className="filter-btn">
                      <img src={tableup} alt="img" className='img-fluid' />
                      <img src={tabledown} alt="img" className='img-fluid' />
                    </div>
                  </div>
                </th> */}
                {/* <th>
                  <div className="parent-tag">
                    DOP Purchased{" "}
                    <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </th> */}
                <th>
                  <div className="parent-tag">
                    Token
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Status{" "}
                    <div className="filter-btn">
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag justify-content-end">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {allRounds?.map((elem, index) => {
                // const date = new Date();
                let date = new Date(elem?.roundData_startTime * 1000);
                let date1 = new Date(elem?.roundData_endTime * 1000);
                let currentTime = new Date(new Date().toUTCString());
                let starttime = new Date(new Date(date).toUTCString());
                let endTime = new Date(new Date(date1).toUTCString());
                const starttimes = moment(date).format("DD-MM-YYYY H:mm:ss");
                const endTimes = moment(date1).format("DD-MM-YYYY H:mm:ss");
                // const currentTime = new Date().getTime();
                // let distandate = currentTime >= date1

                return (
                  <tr>
                    <td>{elem?.newRound ? elem?.newRound : 0}</td>
                    <td>{elem?.roundData_price / 10 ** 18}</td>
                    <td>
                      <div className="twice-text">
                        <h6>{starttimes ? starttimes : 0}</h6>
                        {/* <p>{convertDateFormat(elem?.startDate)?.slice(11, 20)}</p> */}
                      </div>
                    </td>
                    <td>
                      <div className="twice-text">
                        <h6>{endTimes ? endTimes : 0}</h6>
                        {/* <p>{convertDateFormat(elem?.endDate)?.slice(11, 20)}</p> */}
                      </div>
                    </td>
                    {/* <td>{elem?.totalAllocation} DOP</td> */}
                    {/* <td>700,034 DOP</td> */}

                    {/* {elem?.newRound >= 7 ? ( */}

                    <td>
                      <div className="forflex">
                        <p>
                          {roundTokens[elem?.newRound]?.[0]?.symbol}{" "}
                          <span>
                            {roundTokens[elem?.newRound]?.length == 2 ? (
                              <span>
                                {" "}
                                & {roundTokens[elem?.newRound]?.[1]?.symbol}
                              </span>
                            ) : roundTokens[elem?.newRound]?.length > 2 ? (
                              <span>
                                {" "}
                                + {roundTokens[elem?.newRound]?.length - 1}{" "}
                                others
                              </span>
                            ) : null}
                          </span>
                        </p>
                        <svg
                          className="showhiddenmain"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Warning / Info">
                            <path
                              id="Vector"
                              d="M9.16748 8.25V12M9.16748 15.75C5.43956 15.75 2.41748 12.7279 2.41748 9C2.41748 5.27208 5.43956 2.25 9.16748 2.25C12.8954 2.25 15.9175 5.27208 15.9175 9C15.9175 12.7279 12.8954 15.75 9.16748 15.75ZM9.20483 6V6.075L9.13013 6.07515V6H9.20483Z"
                              stroke="#C4C4C4"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                        </svg>
                        <div className="hiddenmain">
                          {roundTokens[elem?.newRound]?.length > 0
                            ? roundTokens[elem?.newRound]?.map((item, i) => {
                                return (
                                  <>
                                    <div className="hiddenflex">
                                      {item?.token ==
                                      "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" ? (
                                        <h6 className="hiddenhead">ETH</h6>
                                      ) : (
                                        <h6 className="hiddenhead">
                                          {item?.symbol}
                                        </h6>
                                      )}

                                      <p className="hiddenpara">
                                        Price:{" "}
                                        {item?.customPrice == 0 ? (
                                          <span>
                                            {elem?.roundData_price / 10 ** 18}
                                          </span>
                                        ) : (
                                          <span>
                                            {item?.customPrice / 10 ** 18}
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </td>
                    {/* // ) : (
                    //   <td>
                    //     <div className="forflex">
                    //       <p>ETH & USDT</p>
                    //       <svg
                    //         className="showhiddenmain"
                    //         width="19"
                    //         height="18"
                    //         viewBox="0 0 19 18"
                    //         fill="none"
                    //         xmlns="http://www.w3.org/2000/svg"
                    //       >
                    //         <g id="Warning / Info">
                    //           <path
                    //             id="Vector"
                    //             d="M9.16748 8.25V12M9.16748 15.75C5.43956 15.75 2.41748 12.7279 2.41748 9C2.41748 5.27208 5.43956 2.25 9.16748 2.25C12.8954 2.25 15.9175 5.27208 15.9175 9C15.9175 12.7279 12.8954 15.75 9.16748 15.75ZM9.20483 6V6.075L9.13013 6.07515V6H9.20483Z"
                    //             stroke="#C4C4C4"
                    //             stroke-width="1.5"
                    //             stroke-linecap="round"
                    //             stroke-linejoin="round"
                    //           />
                    //         </g>
                    //       </svg>
                    //       <div className="hiddenmain">
                    //         <div className="hiddenflex">
                    //           <h6 className="hiddenhead">ETH</h6>
                    //           <p className="hiddenpara">
                    //             Price:{" "}
                    //             <span>{elem?.roundData_price / 10 ** 18}</span>
                    //           </p>
                    //         </div>
                    //         <div className="hiddenflex">
                    //           <h6 className="hiddenhead">USDT</h6>
                    //           <p className="hiddenpara">
                    //             Price:{" "}
                    //             <span>{elem?.roundData_price / 10 ** 18}</span>
                    //           </p>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </td>
                    // )} */}

                    {currentTime < starttime ? (
                      <td className="notstart">Not Started</td>
                    ) : // <td className="notstart">Not Started</td>
                    currentTime > starttime && currentTime < endTime ? (
                      <td className="inprogress">In Progress</td>
                    ) : (
                      currentTime > starttime &&
                      currentTime > endTime && (
                        <td className="ended-text">Ended</td>
                      )
                    )}
                    {/* <td>

                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="\assets\more-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => {
                                setShow111(true);
                                setUserDel(elem);
                              }}
                              class="dropdown-item"
                              href="#"
                            >
                              <img
                                src="\assets\delete.svg"
                                alt="img"
                                className="img-fluid"
                              />
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td> */}
                    <td>
                      <div class="dropdown text-end">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="\assets\more-icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        <ul class="dropdown-menu">
                          {account && account == ownerAddress ? (
                            <>
                              <li>
                                <a
                                  class="dropdown-item"
                                  onClick={() => handliclick(elem)}
                                >
                                  <img
                                    src="\assets\edit.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() => handleClickRound(elem)}
                                >
                                  <img
                                    src="\assets\roundtoken.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Round Token
                                </a>
                              </li>
                              <li>
                                <a
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() =>
                                    handleClickRoundInstitution(elem)
                                  }
                                >
                                  <img
                                    src="\assets\roundtoken.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Round Token Institution
                                </a>
                              </li>
                            </>
                          ) : account !== ownerAddress ? (
                            <>
                              <li>
                                <a
                                  style={{ opacity: "0.5" }}
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() => ownerWalletAddressMessage()}
                                >
                                  <img
                                    src="\assets\edit.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ opacity: "0.5" }}
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() => ownerWalletAddressMessage()}
                                >
                                  <img
                                    src="\assets\roundtoken.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Round Token
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ opacity: "0.5" }}
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() => ownerWalletAddressMessage()}
                                >
                                  <img
                                    src="\assets\roundtoken.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Round Token Institution
                                </a>
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                <a
                                  style={{ opacity: "0.5" }}
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() => connectwalletcond()}
                                >
                                  <img
                                    src="\assets\edit.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ opacity: "0.5" }}
                                  class="dropdown-item asasdasdasdasdasdasdds"
                                  onClick={() => connectwalletcond()}
                                >
                                  <img
                                    src="\assets\roundtoken.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  Round Token
                                </a>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <div className="footer-content">
            <div className="left-f">
            </div>
            <nav className="right-f">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    onClick={() => getPrevData(currentPage)}
                    className="page-link arrowssss scsdsdfefssdvsdvsd"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M10 13L5 8L10 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </button>
                </li>
                {pages?.map((item, index) => {
                  return (
                    <li key={index} className="page-item cursor-pointer">
                      <p
                        className={
                          "page-link " +
                          (index + 1 === parseInt(currentPage)
                            ? "active-pag"
                            : "")
                        }
                        onClick={() => GetAllRounds(index + 1)}
                        style={{ fontSize: "13px !important" }}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                })}
                <li className="page-item">
                  <button
                    onClick={() => getNextData(currentPage)}
                    className="page-link arrowssss"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M5 13L10 8L5 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
        <div className="accmblview d-none">
          <div className="main-twice-text">
            <h6 className="accmblviewhead">Round No </h6>
            <h6 className="accmblviewhead"> Status </h6>
          </div>
          <Accordion>
            {allRounds?.map((elem, index) => {
              // const date = new Date();
              let date = new Date(elem?.roundData_startTime * 1000);
              let date1 = new Date(elem?.roundData_endTime * 1000);
              let currentTime = new Date(new Date().toUTCString());
              let starttime = new Date(new Date(date).toUTCString());
              let endTime = new Date(new Date(date1).toUTCString());
              const starttimes = moment(date).format("DD-MM-YYYY H:mm:ss");
              const endTimes = moment(date1).format("DD-MM-YYYY H:mm:ss");

              // let DateCurrentt = moment(date).utc(true).format('Y-MM-DDTHH:mm')
              // console.log("current", currentTime)
              // console.log("Start time",starttime)
              // console.log("End time",endTime)

              return (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div className="accheadermain">
                      <p className="acctext">
                        {elem?.newRound ? elem?.newRound : 0}
                      </p>
                      {currentTime < starttime ? (
                        <p className="acctext notstart">Not Started</p>
                      ) : currentTime > starttime && currentTime < endTime ? (
                        <p className="acctext inprogress">In Progress</p>
                      ) : (
                        currentTime > starttime &&
                        currentTime > endTime && (
                          <p className="acctext ended-text">Ended </p>
                        )
                      )}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="acctexts">
                      <h6 className="textleft">Sales Price</h6>
                      <p className="textright">
                        ${elem?.roundData_price / 10 ** 18}
                      </p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Starts on</h6>
                      <p className="textright">{starttimes ? starttimes : 0}</p>
                      {/* <p className="textright">{convertDateFormat(elem?.startDate)?.slice(11, 20)}</p> */}
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Ends on</h6>
                      <p className="textright">{endTimes ? endTimes : 0}</p>
                      {/* <p className="textright">{convertDateFormat(elem?.endDate)?.slice(11, 20)}</p> */}
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Token</h6>
                      {elem?.newRound >= 7 ? (
                        <div className="forflex">
                          <p>
                            {roundTokens[elem?.newRound]?.[0]?.symbol}{" "}
                            <span>
                              {roundTokens[elem?.newRound]?.length == 2 ? (
                                <span>
                                  {" "}
                                  & {roundTokens[elem?.newRound]?.[1]?.symbol}
                                </span>
                              ) : roundTokens[elem?.newRound]?.length > 2 ? (
                                <span>
                                  {" "}
                                  + {roundTokens[elem?.newRound]?.length -
                                    1}{" "}
                                  others
                                </span>
                              ) : null}
                            </span>
                          </p>
                          <svg
                            className="showhiddenmain"
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Warning / Info">
                              <path
                                id="Vector"
                                d="M9.16748 8.25V12M9.16748 15.75C5.43956 15.75 2.41748 12.7279 2.41748 9C2.41748 5.27208 5.43956 2.25 9.16748 2.25C12.8954 2.25 15.9175 5.27208 15.9175 9C15.9175 12.7279 12.8954 15.75 9.16748 15.75ZM9.20483 6V6.075L9.13013 6.07515V6H9.20483Z"
                                stroke="#C4C4C4"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                          <div className="hiddenmain">
                            {roundTokens[elem?.newRound]?.length > 0
                              ? roundTokens[elem?.newRound]?.map(
                                  (item, index) => {
                                    return (
                                      <>
                                        <div className="hiddenflex">
                                          {item?.token ==
                                          "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" ? (
                                            <h6 className="hiddenhead">ETH</h6>
                                          ) : (
                                            <h6 className="hiddenhead">
                                              {item?.symbol}
                                            </h6>
                                          )}

                                          <p className="hiddenpara">
                                            Price:{" "}
                                            <span>
                                              {item?.customPrice / 10 ** 18}
                                            </span>
                                          </p>
                                        </div>
                                      </>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      ) : (
                        <div className="forflex">
                          <p>ETH & USDT</p>
                          <svg
                            className="showhiddenmain"
                            width="19"
                            height="18"
                            viewBox="0 0 19 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Warning / Info">
                              <path
                                id="Vector"
                                d="M9.16748 8.25V12M9.16748 15.75C5.43956 15.75 2.41748 12.7279 2.41748 9C2.41748 5.27208 5.43956 2.25 9.16748 2.25C12.8954 2.25 15.9175 5.27208 15.9175 9C15.9175 12.7279 12.8954 15.75 9.16748 15.75ZM9.20483 6V6.075L9.13013 6.07515V6H9.20483Z"
                                stroke="#C4C4C4"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                          <div className="hiddenmain">
                            <div className="hiddenflex">
                              <h6 className="hiddenhead">ETH</h6>
                              <p className="hiddenpara">
                                Price:{" "}
                                <span>{elem?.roundData_price / 10 ** 18}</span>
                              </p>
                            </div>
                            <div className="hiddenflex">
                              <h6 className="hiddenhead">USDT</h6>
                              <p className="hiddenpara">
                                Price:{" "}
                                <span>{elem?.roundData_price / 10 ** 18}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="acctexts dhfvgshdfvhfvdsjfjhf">
                      <button
                        onClick={() => {
                          setShow111(true);
                          setUserDel(elem);
                        }}
                        className="view-btn"
                      >
                        Delete Round
                      </button>
                    </div> */}
                    {/* <div className="acctexts">
                      <h6 className="textleft">Total Allocation</h6>
                      <p className="textright">1,000,000 DOP</p>
                    </div> */}
                    {/* <div className="acctexts">
                      <h6 className="textleft">DOP Purchased</h6>
                      <p className="textright">700,034 DOP</p>
                    </div> */}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          {/* <div className="footer-content">
            <div className="left-f">
            </div>
            <nav className="right-f">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    onClick={() => getPrevData(currentPage)}
                    className="page-link arrowssss scsdsdfefssdvsdvsd"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M10 13L5 8L10 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                  </button>
                </li>
                {pages?.map((item, index) => {
                  return (
                    <li key={index} className="page-item cursor-pointer">
                      <p
                        className={
                          "page-link " +
                          (index + 1 === parseInt(currentPage)
                            ? "active-pag"
                            : "")
                        }
                        onClick={() => GetAllRounds(index + 1)}
                        style={{ fontSize: "13px !important" }}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                })}
                <li className="page-item">
                  <button
                    onClick={() => getNextData(currentPage)}
                    className="page-link arrowssss"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path d="M5 13L10 8L5 3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                  </button>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default DataTable;
