import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Api_Url } from "../../../../utils/ApiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RowListing = ({
  index,
  item,
  usdPrice,
  linkPrice,
  btcPrice,
  pepePrice,
  uniPrice,
  dopPrice,
  user,
}) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [nr, setNr] = useState(0);
  const [earn, setEarn] = useState(0);
  const [earnT, setEarnT] = useState(0);
  const [copy, setCopy] = useState(false);
  const [copy2, setCopy2] = useState(false);
  const [copy3, setCopy3] = useState(false);

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const textCopiedFun2 = (index) => {
    setCopy2(index);

    setTimeout(() => {
      setCopy2(false);
    }, 1000);
  };
  const textCopiedFun3 = (index) => {
    setCopy3(index);

    setTimeout(() => {
      setCopy3(false);
    }, 1000);
  };
  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(2);
    }
  }
  function formatPriceValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(3) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue.toFixed(2);
    }
  }

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  return (
    <>
      <tr className="destopviewss" key={index}>
        <td>
          <div className="twice-text">
            <h6>{moment(item?.transactionTime).format("DD/MM/YYYY")}</h6>
            <p>{moment(item?.transactionTime).format("hh:mm A")} </p>
          </div>
        </td>
        <td>
          <p className="codepara">
            <CopyToClipboard
              text={item?.by}
              onCopy={(e) => textCopiedFun(index)}
            >
              <p>
                {item?.by?.slice(0, 3)}...
                {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  style={{ cursor: "pointer" }}
                  className={copy === index && "copy-button "}
                >
                  <path
                    d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </p>
            </CopyToClipboard>
            {copy === index && <p className="copy-text">Copied</p>}
          </p>
        </td>
        <td>
          <p className="codepara">
            <a
              href={"https://etherscan.io/tx/" + item?.transactionHash}
              target="_blank"
            >
              Etherscan
            </a>
          </p>
        </td>
        <td>{parseFloat(item?.price)?.toFixed(5)}</td>

        <td>
          {parseFloat(item?.amountInvested)?.toFixed(5)} {item?.tokenSelected}
        </td>

        <td>
          {parseFloat(item?.insuranceFee)?.toFixed(5)} {item?.tokenSelected}
        </td>
        <td>{formatTokenValue(item?.dopPurchased)}</td>
        <td>
          <p>{formatTokenValue(item?.tokenInsured)}</p>
        </td>
        {item?.tokenSelected == "ETH" ? (
          <td>
            {parseFloat(item?.totalPaidInToken * usdPrice)?.toFixed(2)} USD
          </td>
        ) : item?.tokenSelected == "LINK" ? (
          <td>
            {parseFloat(item?.totalPaidInToken * linkPrice)?.toFixed(2)} USD
          </td>
        ) : item?.tokenSelected == "WBTC" ? (
          <td>
            {parseFloat(item?.totalPaidInToken * btcPrice)?.toFixed(2)} USD
          </td>
        ) : item?.tokenSelected == "DOP" ? (
          <td>
            {parseFloat(item?.totalPaidInToken * dopPrice)?.toFixed(2)} USD
          </td>
        ) : item?.tokenSelected == "UNI" ? (
          <td>
            {parseFloat(item?.totalPaidInToken * uniPrice)?.toFixed(2)} USD
          </td>
        ) : item?.tokenSelected == "PEPE" ? (
          <td>
            {parseFloat(item?.totalPaidInToken * pepePrice)?.toFixed(2)} USD
          </td>
        ) : (
          <td>{parseFloat(item?.totalPaidInToken)?.toFixed(2)} USD</td>
        )}
      </tr>
      <div className="new-accordionmobile d-none">
        <div className="upper-body">
          <div className="inner-text">
            <h6>Date and time</h6>
            <p> {moment(item?.transactionTime).format("DD/MM/YYYY")}</p>

            <p> {moment(item?.transactionTime).format("hh:mm A")}</p>
          </div>
          <div className="inner-text">
            <h6>Wallet Address</h6>
            <p className="codepara">
              <CopyToClipboard
                text={item?.by}
                onCopy={(e) => textCopiedFun(index)}
              >
                <p>
                  {item?.by?.slice(0, 3)}...
                  {item?.by?.slice(item?.by?.length - 3, item?.by?.length)}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    style={{ cursor: "pointer" }}
                    className={copy === index && "copy-button "}
                  >
                    <path
                      d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </p>
              </CopyToClipboard>
              {copy === index && <p className="copy-text">Copied</p>}
            </p>
          </div>
          <div className="inner-text"></div>
          <div className="inner-text">
            <h6>Hash</h6>
            <p className="codepara">
              <a
                href={"https://etherscan.io/tx/" + item?.transactionHash}
                target="_blank"
              >
                Etherscan
              </a>
            </p>
          </div>
        </div>
        <div className="bottom-body">
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                More Info{" "}
                <img
                  src="\assets\arrow-down.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Accordion.Header>
              <Accordion.Body>
                <div className="inner-textt">
                  <p>Price</p>
                  <h6>{parseFloat(item?.price)?.toFixed(5)}</h6>
                </div>
                <div className="inner-textt">
                  <p>Paid</p>
                  <h6>
                    {parseFloat(item?.amountInvested)?.toFixed(5)}{" "}
                    {item?.tokenSelected}
                  </h6>
                </div>
                <div className="inner-textt">
                  <p>Insuarance Fee</p>
                  {parseFloat(item?.insuranceFee)?.toFixed(5)}{" "}
                  {item?.tokenSelected}
                </div>
                <div className="inner-textt">
                  <p>Amount Purchased</p>
                  <h6>{formatTokenValue(item?.dopPurchased)}</h6>
                </div>

                <div className="inner-textt">
                  <p>Tokens Insured</p>
                  <h6>{formatTokenValue(item?.tokenInsured)}</h6>
                </div>

                <div className="inner-textt">
                  <p>Total Paid</p>

                  <>
                    {item?.tokenSelected == "ETH" ? (
                      <h6>
                        {parseFloat(item?.totalPaidInToken * usdPrice)?.toFixed(
                          2
                        )}{" "}
                        USD
                      </h6>
                    ) : item?.tokenSelected == "LINK" ? (
                      <h6>
                        {parseFloat(
                          item?.totalPaidInToken * linkPrice
                        )?.toFixed(2)}{" "}
                        USD
                      </h6>
                    ) : item?.tokenSelected == "WBTC" ? (
                      <h6>
                        {parseFloat(item?.totalPaidInToken * btcPrice)?.toFixed(
                          2
                        )}{" "}
                        USD
                      </h6>
                    ) : item?.tokenSelected == "DOP" ? (
                      <h6>
                        {parseFloat(item?.totalPaidInToken * dopPrice)?.toFixed(
                          2
                        )}{" "}
                        USD
                      </h6>
                    ) : item?.tokenSelected == "UNI" ? (
                      <h6>
                        {parseFloat(item?.totalPaidInToken * uniPrice)?.toFixed(
                          2
                        )}{" "}
                        USD
                      </h6>
                    ) : item?.tokenSelected == "PEPE" ? (
                      <h6>
                        {parseFloat(
                          item?.totalPaidInToken * pepePrice
                        )?.toFixed(2)}{" "}
                        USD
                      </h6>
                    ) : (
                      <h6>
                        {parseFloat(item?.totalPaidInToken)?.toFixed(2)} USD
                      </h6>
                    )}
                  </>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default RowListing;
