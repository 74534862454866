import React, { useState } from "react";
import "./forgot.scss";
import Navbar from "../landing/header/Navbar";
import { Link } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import { toast } from "react-toastify";

const Forgot = () => {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  const ForgetPassword = async () => {
    const lowerCaseEmail = email.toLowerCase();
    const data = { email: lowerCaseEmail };
    if (isValidEmail(email)) {
      setLoader(true);
      await axios
        .post(`${Api_Url}/auth/users/forget-password`, data)
        .then((res) => {
          toast.success("Please check email!");
          setLoader(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });
    } else {
      setError("Incorrect Email!");
    }
  };

  return (
    <>
      <div className="forgotpassword-wrapper">
        <Navbar />
        <section className="forgot-password">
          <div className="custom-container">
            <Link to="/login">
              <button className="btn-back">
                <img
                  src="\assets\forgot\arrow-left.svg"
                  alt="img"
                  className="img-fluid"
                />
                Back
              </button>
            </Link>
            <div className="parent-card">
              {/* <div className="upper-logo">
                <img
                  src="\assets\forgot\forgot-logo.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div> */}
              <div className="bottom-content">
                {/* <img
                  src="\assets\forgot\happyface.svg"
                  alt="img"
                  className="img-fluid"
                /> */}
                <h6>Forgot your password?</h6>
                <p>
                  Enter your registered email to receive password reset
                  instructions.
                </p>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      placeholder="Enter Your Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                    />
                    <label>Email Address</label>
                  </div>
                </div>
                <button onClick={() => ForgetPassword()} className="btn-send">
                  Send
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Forgot;
