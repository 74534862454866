import React, { useEffect, useState } from "react";
import "./allinstitution.scss";
import DataTable from "./DataTable/DataTable";
import ViewDetail from "./ViewDetail";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Api_Url } from "../../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import { toast } from "react-toastify";

const AllInstitution = ({
  user,
  setindexwait,
  GetMyData,
  routes,
  setRoutes,
  usdPrice,
  tomiPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  gemsPrice,
  dopPrice,
}) => {
  const [loader, setLoader] = useState(false);
  const [leadersLoader, setLeadersLoader] = useState(false);
  const [project, setProject] = useState("LEIA");

  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(1);
  const [search0, setSearch0] = useState("");
  const [userDel, setUserDel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [orderField, setOrderField] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("-1");
  const history = useHistory();

  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setName("");
    setAddr("");
    setEmail("");
    setRole(null);
    setShow1(false);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  function isAddressValid(address) {
    return Web3.utils.isAddress(address);
  }

  useEffect(() => {
    getLeaderNames();
  }, []);

  useEffect(() => {
    if (user) {
      GetAgents();
    }
  }, [
    user,
    leaderFilter,
    roleTypeFilter,
    searchQuery,
    orderField,
    orderDirection,
    project,
  ]);

  // useEffect(() => {
  //   if (leaderFilter !== "") {
  //     GetAgents();
  //   }
  // }, [leaderFilter]);

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/institutions/names?limit=${500}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
        setLeaderNames2(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
      });
  };

  const GetAgents = (off) => {
    setLeadersLoader(true);
    const val = localStorage.getItem("accessToken");
    let valu = null;
    if (off) {
      valu = off;
    } else {
      valu = 1;
    }
    var config = {
      method: "get",
      url: `${Api_Url}/institutions?limit=${10}&offset=${valu}&privateSale=${project}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;

    if (leaderFilter) {
      dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    if (searchQuery) {
      dynamicQuery += `&search=${encodeURIComponent(searchQuery)}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.insitutions);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPages(arr);
        setCurrentPage(valu);
        // setSearch('')
        if (off <= response.data.data.squad.length) {
          if (off - 1 == 0) {
            setLimit(1);
          } else {
            setLimit((off - 1) * 5);
          }
        }
        setLeadersLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        setLeadersLoader(false);
      });
  };

  const getPrevData = (off) => {
    let offset = parseInt(off) - 1;
    const val = localStorage.getItem("accessToken");
    if (offset > 0) {
      // setLoader(true);
      var config = null;
      config = {
        method: "get",
        url: `${Api_Url}/institutions?offset=${offset}&limit=10&privateSale=${project}&orderField=createdAt&orderDirection=-1`,
        headers: {
          Authorization: "Bearer " + val,
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          const resData = response?.data?.data;
          let arr = Array.from(
            Array(parseInt(response.data.data.pages)).keys()
          );
          setPages(arr);
          setAgents(resData?.insitutions);
          if (currentPage - 1 >= 0) {
            setCurrentPage(currentPage - 1);
          }
          if (off >= 0) {
            if (offset - 1 == 0) {
              setLimit(1);
            } else {
              setLimit((offset - 1) * 5);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // setLoader(false);
    }
  };

  const getNextData = (off) => {
    let offset = parseInt(off) + 1;
    const val = localStorage.getItem("accessToken");
    if (pages.length > off) {
      if (off < agents.length) {
        var config = null;
        config = {
          method: "get",
          url: `${Api_Url}/institutions?offset=${offset}&limit=10&privateSale=${project}&orderField=createdAt&orderDirection=-1`,
          headers: {
            Authorization: "Bearer " + val,
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            const resData = response?.data?.data;
            let arr = Array.from(
              Array(parseInt(response.data.data.pages)).keys()
            );
            setPages(arr);
            setAgents(resData?.insitutions);
            if (off <= agents.length) {
              setCurrentPage(offset);
              setLimit(off * 5);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  const AddAgent = () => {
    const val = localStorage.getItem("accessToken");
    let url = null;
    if (user?.role === "admin") {
      url = `${Api_Url}/users/god-agents`;
    } else if (role === "mega_agent") {
      url = `${Api_Url}/users/mega-agents`;
    } else if (role === "super_agent") {
      url = `${Api_Url}/users/super-agents`;
    } else if (role === "agent") {
      url = `${Api_Url}/users/agents`;
    }
    if (name?.length > 2) {
      if (isAddressValid(addr)) {
        if (isValidEmail(email)) {
          setLoader(true);
          var config = {
            method: "post",
            url: url,
            data: {
              name: name,
              walletAddress: addr,
              email: email,
              name: name,
            },
            headers: {
              Authorization: "Bearer " + val,
            },
          };

          axios(config)
            .then(function (response) {
              setLoader(false);
              setName("");
              setAddr("");
              setEmail("");
              setRole(null);
              GetMyData();
              handleClose1();
              setShow2(true);
              GetAgents();
            })
            .catch(function (error) {
              setLoader(false);
              toast.error(error?.response?.data?.message);
              if (error?.response?.status == 401) {
                localStorage.removeItem("accessToken");
                history.push("/");
              }
            });
        } else {
          setError("Incorrect Email!");
        }
      } else {
        setError("Incorrect wallet address!");
      }
    } else {
      setError("Name must be of length 3!");
    }
  };

  function convertToTitleCase(input) {
    // console.log("cccc");
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const DeleteUser = () => {
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "delete",
      url: `${Api_Url}/users/${userDel?._id}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        GetAgents();
        setShow(false);
        setRoutes(false);
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        toast.error(error?.message);
      });
  };

  const functiontogetback = () => {
    localStorage.setItem("indexvalue", 0);
    setindexwait(0);
  };

  const SearchByName = (e) => {
    e?.preventDefault();
    if (search0?.length > 1) {
      const results = leaderNames.filter((item) =>
        item.name?.toLowerCase().includes(search0?.toLowerCase())
      );
      setLeaderNames(results);
    } else if (search0?.length === 0) {
      setLeaderNames(leaderNames2);
    }
    if (search0 === "") {
      GetAgents();
    }
  };

  const GetSearch = (searchKey) => {
    setSearch0(searchKey);
    if (searchKey === "") {
      GetAgents();
      if (searchKey?.length > 1) {
        const results = leaderNames.filter((item) =>
          item.name?.toLowerCase().includes(searchKey?.toLowerCase())
        );
        setLeaderNames(results);
      } else if (searchKey?.length === 0) {
        setLeaderNames(leaderNames2);
      }
    } else {
      SearchByName();
    }
  };

  const [selectedItem, setSelectedItem] = useState({
    imgSrc: "/assets/leia.svg",
    text: "LEIA",
  });

  const handleItemClick = (imgSrc, text) => {
    setSelectedItem({ imgSrc, text });
    setProject(text);
  };
  return (
    <>
      {routes ? (
        <>
          <div className="allinstitution-agents">
            <ViewDetail
              DeleteUser={DeleteUser}
              user={user}
              userDel={userDel}
              setRoutes={setRoutes}
              setUserDel={setUserDel}
              shouldUpdate={true}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              pepePrice={pepePrice}
              gemsPrice={gemsPrice}
              dopPrice={dopPrice}
              project={project}
            />
          </div>
        </>
      ) : (
        <section className="allagents allinstitution-agents">
          <div className="custom-container">
            <button
              class="btn-back guvxgyvxvtxx"
              onClick={() => functiontogetback()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M8.37376 16.4675C8.20751 16.4675 8.04126 16.4062 7.91001 16.275L2.59876 10.9637C2.34501 10.71 2.34501 10.29 2.59876 10.0362L7.91001 4.72498C8.16376 4.47123 8.58376 4.47123 8.83751 4.72498C9.09126 4.97873 9.09126 5.39873 8.83751 5.65248L3.99001 10.5L8.83751 15.3475C9.09126 15.6012 9.09126 16.0212 8.83751 16.275C8.71501 16.4062 8.54001 16.4675 8.37376 16.4675Z"
                  fill="black"
                ></path>
                <path
                  d="M17.9375 11.1562H3.21124C2.85249 11.1562 2.55499 10.8587 2.55499 10.5C2.55499 10.1413 2.85249 9.84375 3.21124 9.84375H17.9375C18.2962 9.84375 18.5937 10.1413 18.5937 10.5C18.5937 10.8587 18.2962 11.1562 17.9375 11.1562Z"
                  fill="black"
                ></path>
              </svg>{" "}
              Back
            </button>
            <div className="main-heading">
              <h6>All Institutions</h6>
              <div className="newbtnsets" style={{ alignItems: "center" }}>
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="projectmain">
                      {selectedItem.imgSrc && (
                        <img
                          src={selectedItem.imgSrc}
                          alt="projectimg"
                          className="projectimg"
                        />
                      )}
                      <p className="projectpara">{selectedItem.text}</p>
                    </div>
                    <img
                      src="/assets/arrow-down.svg"
                      alt="arrow-down"
                      className="img-fluid"
                    />
                  </button>
                  <ul className="dropdown-menu">
                    {/* <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => handleItemClick("", "All Projects")}
                      >
                        All Projects
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() =>
                          handleItemClick("/assets/leia.svg", "LEIA")
                        }
                      >
                        <div className="projectmain">
                          <img
                            src="/assets/leia.svg"
                            alt="projectimg"
                            className="projectimg"
                          />
                          <p className="projectpara">Leia</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() =>
                          handleItemClick("/assets/olympus.svg", "OLYMPUS")
                        }
                      >
                        <div className="projectmain">
                          <img
                            src="/assets/olympus.svg"
                            alt="projectimg"
                            className="projectimg"
                            width={24}
                          />
                          <p className="projectpara">Olypmus</p>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() =>
                          handleItemClick("/assets/dop.svg", "DOP")
                        }
                      >
                        <div className="projectmain">
                          <img
                            src="/assets/dop.svg"
                            alt="projectimg"
                            className="projectimg"
                          />
                          <p className="projectpara">DOP</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="rightside-content">
                  <div className="custom-option-field">
                    <input
                      type="search"
                      placeholder="Search by address..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                    />

                    <img
                      src="\assets\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                    />
                  </div>
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div
                        onClick={() => {
                          // setLeaderFilter("");
                        }}
                        className="se-custom-flex-gap"
                      >
                        {leaderFilter ? leaderFilter + " " : "By Name "}

                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    </button>
                    <ul class="dropdown-menu gycytctdtrctrc">
                      <div className="custom-option-field">
                        <form onSubmit={(e) => SearchByName(e)}>
                          <input
                            value={search0}
                            onChange={(e) => GetSearch(e.target.value)}
                            type="search"
                            placeholder="Search"
                          />
                        </form>
                        <img
                          onClick={() => SearchByName()}
                          style={{ cursor: "pointer" }}
                          src="\assets\search-icon.svg"
                          alt="img"
                          className="img-fluid search-icon"
                        />
                      </div>
                      {/* GetAgents */}
                      {/* <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          setName("");
                          GetAgents();
                        }}
                      >
                        View All
                      </a>
                    </li> */}
                      {leaderNames?.length > 0
                        ? leaderNames?.map((item) => {
                            return (
                              <>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      setLeaderFilter(item?.name);
                                      setSearch0(item?.name);
                                    }}
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              </>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                  {/* <button
                  className="reset-btn-global"
                  onClick={() => {
                    setRoleTypeFilter("");
                    setLeaderFilter("");
                    setSearchQuery("");
                  }}
                >
                  Reset
                </button> */}
                </div>
              </div>
            </div>
            {agents?.length > 0 ? (
              <DataTable
                setUserDel={setUserDel}
                getNextData={getNextData}
                currentPage={currentPage}
                GetAllRounds={GetAgents}
                pages={pages}
                getPrevData={getPrevData}
                GetAgents={GetAgents}
                page={page}
                offset={offset}
                setOffset={setOffset}
                agents={agents}
                setAgents={setAgents}
                setShow={setShow}
                setRoutes={setRoutes}
                setOrderDirection={setOrderDirection}
                setOrderField={setOrderField}
                tomiPrice={tomiPrice}
                usdPrice={usdPrice}
                btcPrice={btcPrice}
                project={project}
                dopPrice={dopPrice}
              />
            ) : leadersLoader ? (
              <p
                className=""
                style={{
                  color: "#fff",
                  textAlign: "center",
                  marginTop: 100,
                }}
              >
                Loading...
              </p>
            ) : agents?.length == 0 ? (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                No data found!
              </p>
            ) : null}
          </div>
        </section>
      )}
      <Modal
        className="delete-modal global-modal-scss"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-modal-div">
            <img
              src="\assets\delete-modal-icon.svg"
              alt="img"
              className="img-fluid"
            />
            <h6>Are you sure you want to delete this?</h6>
            <p>This will delete it permanently. You cannot undo this action.</p>
          </div>
          <div className="twice-btn">
            <button onClick={() => handleClose()} className="btn-cancel">
              Cancel
            </button>
            <button className="btn-del" onClick={() => DeleteUser()}>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          {user?.role === "admin" ? (
            <Modal.Title>Add God Leader</Modal.Title>
          ) : (
            <Modal.Title>Add Leader</Modal.Title>
          )}
          {/* {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>}
                    {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>} */}
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Leader’s Name"
                />
                <label>Leader’s Name</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  type="text"
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  placeholder="Enter Leader’s Wallet Address"
                />
                <label>Leader’s Wallet Address</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="text"
                  placeholder="Enter Leader’s Email Address"
                />
                <label>Leader’s Email Address</label>
              </div>
            </div>
            {user?.role !== "admin" && (
              <div className="option-field">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {role === null
                      ? "Type of Leader"
                      : convertToTitleCase(role)}
                  </button>
                  {user?.role === "god_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("mega_agent")}>
                        <a class="dropdown-item" href="#">
                          Mega Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("super_agent")}>
                        <a class="dropdown-item" href="#">
                          Super Leader
                        </a>
                      </li>
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "mega_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("super_agent")}>
                        <a class="dropdown-item" href="#">
                          Super Leader
                        </a>
                      </li>
                    </ul>
                  )}
                  {user?.role === "super_agent" && (
                    <ul class="dropdown-menu">
                      <li onClick={() => setRole("agent")}>
                        <a class="dropdown-item" href="#">
                          Leader
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: -15,
              marginBottom: 15,
            }}
          >
            {error}
          </p>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
                setError("");
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button
              disabled={loader}
              className="btn-add"
              onClick={() => AddAgent()}
            >
              {loader ? "Adding..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          {user?.role === "admin" ? (
            <Modal.Title>Add Mega Leaders</Modal.Title>
          ) : (
            <Modal.Title>Add Leaders</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            {user?.role === "admin" ? (
              <h6>God Leader has been added</h6>
            ) : (
              <h6>{convertToTitleCase(role)} has been added</h6>
            )}
          </div>
          <div className="twice-btn">
            <button
              onClick={() => handleClose2()}
              className="btn-add"
              style={{ flex: "auto" }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllInstitution;
