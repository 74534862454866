import React, { useState } from 'react'
import "./forgot.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Api_Url } from '../../utils/ApiUrl'
import { toast } from 'react-toastify'

const NewPassword = () => {
    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);


    function validatePassword(password) {
        // At least 8 characters long
        if (password.length < 8) return false;

        // At least one uppercase letter
        if (!/[A-Z]/.test(password)) return false;

        // At least one special character
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return false;

        return true;
    }


    const ResetPassword = async () => {
        var val = window.location.href;
        val = new URL(val);
        var tok = val.searchParams.get("token");
        const data = { password: password, confirmPassword: confirmPassword, token: tok };
        let isValid = validatePassword(password);
        if (isValid) { // Fix here - we want to proceed if the password is valid
            if (password === confirmPassword) {
                setLoader(true);
                await axios
                    .post(`${Api_Url}/auth/users/reset-password`, data)
                    .then((res) => {
                        toast.success("Password updated successfully!");
                        setLoader(false);
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data.message, {
                            position: "top-right",
                            autoClose: 2000,
                        });
                        setLoader(false);
                    });
            } else {
                toast?.error("Password's do not match!");
            }
        } else {
            toast?.error("Password does not match requirements!");
        }
    };

    return (
        <>
            <div className="forgotpassword-wrapper">
                <Navbar />
                <section className="forgot-password">
                    <div className="custom-container">
                        <Link to="/forgot"><button className='btn-back'><img src="\assets\forgot\arrow-left.svg" alt="img" className='img-fluid' />Back</button></Link>
                        <div className="parent-card">
                            {/* <div className="upper-logo">
                                <img src="\assets\forgot\forgot-logo.svg" alt="img" className='img-fluid' />
                            </div> */}
                            <div className="bottom-content">
                                <h6>Reset your Password</h6>
                                <div className="option-field">
                                    <div className="material-textfield">
                                        <input
                                            placeholder="New Password"
                                            type={showPassword ? "text" : "password"}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <label>New Password</label>
                                    </div>
                                    <img onClick={() => setShowPassword(!showPassword)} src="\eye.svg" alt="img" className='img-fluid password-icon' />
                                </div>
                                <div className="listing-item">
                                    <h6>Password Must:</h6>
                                    <ul>
                                        <li>Be at least 8 characters long</li>
                                        <li>Have at least one uppercase</li>
                                        <li>Contain at least one special character</li>
                                    </ul>
                                </div>
                                <div className="option-field">
                                    <div className="material-textfield">
                                        <input
                                            placeholder="Confirm New Password"
                                            type={showPassword1 ? "text" : "password"}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        // className='iferrorinput'
                                        />
                                        <label>Confirm New Password</label>
                                    </div>
                                    <img onClick={() => setShowPassword1(!showPassword1)} src="\eye.svg" alt="img" className='img-fluid password-icon' />
                                </div>
                                <button onClick={() => ResetPassword()} className='btn-send'>{loader ? "Confirming" : "Confirm"}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
}

export default NewPassword;
