import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DataTable = ({
  setRoutes,
  setShow,
  agents,
  setAgents,
  pages,
  currentPage,
  GetAllRounds,
  getNextData,
  getPrevData,
  setUserDel,
  sub,
  setOrderDirection,
  setOrderField,
  project,
  dopPrice,
}) => {
  const [usdPrice, setUsdPrice] = useState(0);
  const [rend, setRend] = useState(false);
  const [tomiPrice, setTomiPrice] = useState("");

  const getTomiPrice = async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price`,
        {
          params: {
            ids: "tomiNet",
            vs_currencies: "usd",
          },
        }
      );

      const data = response?.data;
      const priceInUSD = data["tominet"]?.usd;

      setTomiPrice(priceInUSD);
    } catch (error) {
      console.error("Error fetching data:", error);
      // throw error;
    }
  };
  useEffect(() => {
    getTomiPrice();
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => {
        setUsdPrice(res?.data?.USD);
        // setEthUsd(res.data);
      });
  }, []);

  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[words.length - 1].toLowerCase() === "agent") {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  function convertDateFormat(input) {
    const date = new Date(input);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}\n${hours}:${minutes} UTC`;
  }

  const EarningsSort = (val) => {
    let data = agents;
    if (val === "asc") {
      data.sort(
        (a, b) =>
          b?.myEarningsEth * usdPrice +
          b?.myEarningsUsdt -
          (a?.myEarningsEth * usdPrice + a?.myEarningsUsdt)
      );
    } else {
      data.sort(
        (a, b) =>
          a?.myEarningsEth * usdPrice +
          a?.myEarningsUsdt -
          (b?.myEarningsEth * usdPrice + b?.myEarningsUsdt)
      );
    }
    setAgents(data);
    setRend(!rend);
  };

  const PurchasesSort = (val) => {
    let data = agents;
    if (val === "asc") {
      data.sort(
        (a, b) =>
          b?.totalEth * usdPrice +
          b?.totalUsdt -
          (a?.totalEth * usdPrice + a?.totalUsdt)
      );
    } else {
      data.sort(
        (a, b) =>
          a?.totalEth * usdPrice +
          a?.totalUsdt -
          (b?.totalEth * usdPrice + b?.totalUsdt)
      );
    }
    setAgents(data);
    setRend(!rend);
  };

  return (
    <>
      <section className="datatable">
        <div className="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date/Time{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("createdAt");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Name{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("name");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Wallet Address{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    {project} Purchased{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("totalDop");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Total Purchases{" "}
                    <div className="filter-btn">
                      <img
                        onClick={() => PurchasesSort("asc")}
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        onClick={() => PurchasesSort("desc")}
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </th>
                {sub != true ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {agents?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="twice-text">
                        <h6>
                          {convertDateFormat(item?.createdAt)?.slice(0, 10)}
                        </h6>
                        <p>
                          {convertDateFormat(item?.createdAt)?.slice(12, 20)}
                        </p>
                      </div>
                    </td>
                    <td>{item?.name}</td>
                    <td>
                      {copy ? (
                        <span className="adadasdasdasdasdasdsad">
                          {item?.walletAddress?.slice(0, 11)}...
                          {item?.walletAddress?.slice(
                            item?.walletAddress?.length - 3,
                            item?.walletAddress?.length
                          )}{" "}
                          <svg
                            style={{ cursor: "pointer" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className=""
                          >
                            <path
                              d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      ) : (
                        <>
                          {item?.walletAddress && (
                            <CopyToClipboard
                              text={item?.walletAddress}
                              onCopy={textCopiedFun2}
                            >
                              <span className="adadasdasdasdasdasdsad">
                                {item?.walletAddress?.slice(0, 11)}...
                                {item?.walletAddress?.slice(
                                  item?.walletAddress?.length - 3,
                                  item?.walletAddress?.length
                                )}{" "}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className=""
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                            </CopyToClipboard>
                          )}
                        </>
                      )}
                    </td>
                    {/* <td>
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}{" "}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </td> */}
                    {item?.directDopPurchased?.length > 0 ? (
                      <td>
                        {(item?.directDopPurchased[0]?.totalDirectDop
                          ? item?.directDopPurchased[0]?.totalDirectDop
                          : 0
                        )?.toFixed(4)}{" "}
                        {project}
                      </td>
                    ) : (
                      <td>
                        {item?.dopPurchased[0]?.totalDopPurchased?.toFixed(4)}{" "}
                        {project}
                      </td>
                    )}
                    <td>
                      <div className="twice-text">
                        <>
                          <h6>
                            $
                            {parseFloat(
                              (
                                usdPrice *
                                  (item.totalPurchases &&
                                  item.totalPurchases?.length > 0 &&
                                  item.totalPurchases[0]?._id == "ETH"
                                    ? item.totalPurchases[0]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[1]?._id == "ETH"
                                    ? item.totalPurchases[1]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[2]?._id == "ETH"
                                    ? item.totalPurchases[2]?.purchasedAmount
                                    : 0) +
                                (item.totalPurchases &&
                                item.totalPurchases?.length > 0 &&
                                item.totalPurchases[0]?._id == "USDT"
                                  ? item.totalPurchases[0]?.purchasedAmount
                                  : item.totalPurchases &&
                                    item.totalPurchases?.length > 0 &&
                                    item.totalPurchases[1]?._id == "USDT"
                                  ? item.totalPurchases[1]?.purchasedAmount
                                  : item.totalPurchases &&
                                    item.totalPurchases?.length > 0 &&
                                    item.totalPurchases[2]?._id == "USDT"
                                  ? item.totalPurchases[2]?.purchasedAmount
                                  : 0) +
                                tomiPrice *
                                  (item.totalPurchases &&
                                  item.totalPurchases?.length > 0 &&
                                  item.totalPurchases[0]?._id == "TOMI"
                                    ? item.totalPurchases[0]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[1]?._id == "TOMI"
                                    ? item.totalPurchases[1]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[2]?._id == "TOMI"
                                    ? item.totalPurchases[2]?.purchasedAmount
                                    : 0) +
                                dopPrice *
                                  (item.totalPurchases &&
                                  item.totalPurchases?.length > 0 &&
                                  item.totalPurchases[0]?._id == "DOP"
                                    ? item.totalPurchases[0]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[1]?._id == "DOP"
                                    ? item.totalPurchases[1]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[2]?._id == "DOP"
                                    ? item.totalPurchases[2]?.purchasedAmount
                                    : 0)
                              )?.toFixed(4)
                            ).toLocaleString("en-US")}
                          </h6>
                          <p>
                            ({" "}
                            {item.totalPurchases &&
                            item.totalPurchases?.length > 0 &&
                            item.totalPurchases[0]?._id == "ETH"
                              ? item.totalPurchases[0]?.purchasedAmount
                              : item.totalPurchases &&
                                item.totalPurchases[1]?._id == "ETH"
                              ? item.totalPurchases[1]?.purchasedAmount
                              : item.totalPurchases &&
                                item.totalPurchases[2]?._id == "ETH"
                              ? item.totalPurchases[2]?.purchasedAmount
                              : 0}{" "}
                            ETH +{" "}
                            {item.totalPurchases &&
                            item.totalPurchases?.length > 0 &&
                            item.totalPurchases[0]?._id == "USDT"
                              ? item.totalPurchases[0]?.purchasedAmount
                              : item.totalPurchases &&
                                item.totalPurchases[1]?._id == "USDT"
                              ? item.totalPurchases[1]?.purchasedAmount
                              : item.totalPurchases &&
                                item.totalPurchases[2]?._id == "USDT"
                              ? item.totalPurchases[2]?.purchasedAmount
                              : 0}{" "}
                            USDT +{" "}
                            {item.totalPurchases &&
                            item.totalPurchases?.length > 0 &&
                            item.totalPurchases[0]?._id == "DOP"
                              ? item.totalPurchases[0]?.purchasedAmount
                              : item.totalPurchases &&
                                item.totalPurchases[1]?._id == "DOP"
                              ? item.totalPurchases[1]?.purchasedAmount
                              : item.totalPurchases &&
                                item.totalPurchases[2]?._id == "DOP"
                              ? item.totalPurchases[2]?.purchasedAmount
                              : 0}{" "}
                            DOP )
                          </p>
                        </>
                      </div>
                    </td>
                    {sub != true ? (
                      <td>
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="\assets\more-icon.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => {
                                  setRoutes(true);
                                  setUserDel(item);
                                }}
                                class="dropdown-item"
                                href="#"
                              >
                                <img
                                  src="\assets\viewdetail.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                                View Details
                              </a>
                            </li>
                            {/* <li><a class="dropdown-item" href="#"><img src="\assets\edit.svg" alt="img" className="img-fluid" />Edit</a></li> */}
                            <li>
                              <a
                                onClick={() => {
                                  setShow(true);
                                  setUserDel(item);
                                }}
                                class="dropdown-item"
                                href="#"
                              >
                                <img
                                  src="\assets\delete.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {pages?.length > 0 ? (
            <div className="footer-content">
              <div className="left-f">{/* <h6>SHOWING 1-10 OF 145</h6> */}</div>
              <div className="right-f">
                <nav className="right-f">
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        onClick={() => getPrevData(currentPage)}
                        className="page-link arrowssss scsdsdfefssdvsdvsd"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M10 13L5 8L10 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </li>
                    {pages?.map((item, index) => {
                      return (
                        <li key={index} className="page-item cursor-pointer">
                          <p
                            className={
                              "page-link " +
                              (index + 1 === parseInt(currentPage)
                                ? "active-pag"
                                : "")
                            }
                            onClick={() => GetAllRounds(index + 1)}
                            style={{ fontSize: "13px !important" }}
                          >
                            {index + 1}
                          </p>
                        </li>
                      );
                    })}
                    <li className="page-item">
                      <button
                        onClick={() => getNextData(currentPage)}
                        className="page-link arrowssss"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M5 13L10 8L5 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="mobiledesignss  d-none">
          {agents?.map((item, index) => {
            return (
              <div className="new-accordionmobile dsuygfdsyufgsdyuf d-none">
                <div className="upper-body">
                  <div className="inner-text">
                    <h6>Date and time</h6>
                    <p> {convertDateFormat(item?.createdAt)?.slice(0, 10)}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Leader</h6>
                    <p>{item?.name}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Role</h6>
                    <p>{convertToTitleCase(item?.role)}</p>
                  </div>
                  <div className="inner-textt">
                    <p>Wallet Address</p>
                    {copy ? (
                      <p className="adadasdasdasdasdasdsad">
                        {item?.walletAddress?.slice(0, 11)}...
                        {item?.walletAddress?.slice(
                          item?.walletAddress?.length - 3,
                          item?.walletAddress?.length
                        )}
                        <svg
                          style={{ cursor: "pointer" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className=""
                        >
                          <path
                            d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                    ) : (
                      <>
                        {item?.walletAddress && (
                          <CopyToClipboard
                            text={item?.walletAddress}
                            onCopy={textCopiedFun2}
                          >
                            <p className="adadasdasdasdasdasdsad">
                              {item?.walletAddress?.slice(0, 11)}...
                              {item?.walletAddress?.slice(
                                item?.walletAddress?.length - 3,
                                item?.walletAddress?.length
                              )}
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                className=""
                              >
                                <path
                                  d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </p>
                          </CopyToClipboard>
                        )}
                      </>
                    )}
                  </div>
                  {/* <div className="inner-text">
                    <h6>Wallet Address</h6>
                    <p className="textright">
                      {item?.walletAddress?.slice(0, 11)}...
                      {item?.walletAddress?.slice(
                        item?.walletAddress?.length - 3,
                        item?.walletAddress?.length
                      )}
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(item?.walletAddress);
                        }}
                        src="\assets\copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </p>
                  </div> */}
                </div>
                <div className="bottom-body">
                  <Accordion>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        More Info{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-textt">
                          <p>{project} Purchased</p>
                          {item?.directDopPurchased?.length > 0 ? (
                            <h6 className="textright">
                              {(item?.directDopPurchased[0]?.totalDirectDop
                                ? item?.directDopPurchased[0]?.totalDirectDop
                                : 0
                              )?.toFixed(4)}{" "}
                              {project}
                            </h6>
                          ) : (
                            <h6 className="textright">
                              {0} {project}
                            </h6>
                          )}
                        </div>

                        {/* totalPurchaseUsdt */}
                        <div className="inner-textt">
                          <p>Total Purchases</p>
                          <div className="twice-text">
                            <h6>
                              $
                              {parseFloat(
                                (
                                  usdPrice *
                                    (item.totalPurchases &&
                                    item.totalPurchases?.length > 0 &&
                                    item.totalPurchases[0]?._id == "ETH"
                                      ? item.totalPurchases[0]?.purchasedAmount
                                      : item.totalPurchases &&
                                        item.totalPurchases?.length > 0 &&
                                        item.totalPurchases[1]?._id == "ETH"
                                      ? item.totalPurchases[1]?.purchasedAmount
                                      : item.totalPurchases &&
                                        item.totalPurchases?.length > 0 &&
                                        item.totalPurchases[2]?._id == "ETH"
                                      ? item.totalPurchases[2]?.purchasedAmount
                                      : 0) +
                                  (item.totalPurchases &&
                                  item.totalPurchases?.length > 0 &&
                                  item.totalPurchases[0]?._id == "USDT"
                                    ? item.totalPurchases[0]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[1]?._id == "USDT"
                                    ? item.totalPurchases[1]?.purchasedAmount
                                    : item.totalPurchases &&
                                      item.totalPurchases?.length > 0 &&
                                      item.totalPurchases[2]?._id == "USDT"
                                    ? item.totalPurchases[2]?.purchasedAmount
                                    : 0) +
                                  tomiPrice *
                                    (item.totalPurchases &&
                                    item.totalPurchases?.length > 0 &&
                                    item.totalPurchases[0]?._id == "TOMI"
                                      ? item.totalPurchases[0]?.purchasedAmount
                                      : item.totalPurchases &&
                                        item.totalPurchases?.length > 0 &&
                                        item.totalPurchases[1]?._id == "TOMI"
                                      ? item.totalPurchases[1]?.purchasedAmount
                                      : item.totalPurchases &&
                                        item.totalPurchases?.length > 0 &&
                                        item.totalPurchases[2]?._id == "TOMI"
                                      ? item.totalPurchases[2]?.purchasedAmount
                                      : 0)
                                )?.toFixed(4)
                              ).toLocaleString("en-US")}
                            </h6>
                            <p>
                              ({" "}
                              {item.totalPurchases &&
                              item.totalPurchases?.length > 0 &&
                              item.totalPurchases[0]?._id == "ETH"
                                ? item.totalPurchases[0]?.purchasedAmount
                                : item.totalPurchases &&
                                  item.totalPurchases[1]?._id == "ETH"
                                ? item.totalPurchases[1]?.purchasedAmount
                                : item.totalPurchases &&
                                  item.totalPurchases[2]?._id == "ETH"
                                ? item.totalPurchases[2]?.purchasedAmount
                                : 0}{" "}
                              ETH +{" "}
                              {item.totalPurchases &&
                              item.totalPurchases?.length > 0 &&
                              item.totalPurchases[0]?._id == "USDT"
                                ? item.totalPurchases[0]?.purchasedAmount
                                : item.totalPurchases &&
                                  item.totalPurchases[1]?._id == "USDT"
                                ? item.totalPurchases[1]?.purchasedAmount
                                : item.totalPurchases &&
                                  item.totalPurchases[2]?._id == "USDT"
                                ? item.totalPurchases[2]?.purchasedAmount
                                : 0}{" "}
                              USDT +{" "}
                              {/* {item.totalPurchases &&
                              item.totalPurchases?.length > 0 &&
                              item.totalPurchases[0]?._id == "TOMI"
                                ? item.totalPurchases[0]?.purchasedAmount
                                : item.totalPurchases &&
                                  item.totalPurchases[1]?._id == "TOMI"
                                ? item.totalPurchases[1]?.purchasedAmount
                                : item.totalPurchases &&
                                  item.totalPurchases[2]?._id == "TOMI"
                                ? item.totalPurchases[2]?.purchasedAmount
                                : 0}{" "}
                              TOMI */}
                              )
                            </p>
                          </div>
                        </div>
                        <div className="inner-textt">
                          <button
                            onClick={() => {
                              setRoutes(true);
                              setUserDel(item);
                            }}
                            className="view-btn"
                          >
                            View Details
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
              // <Accordion.Item key={index} eventKey={index}>
              //   <Accordion.Header>
              //     <div className="accheadermain">
              //       <p className="acctext">
              //         {convertDateFormat(item?.createdAt)?.slice(0, 10)}
              //       </p>
              //       <p className="acctext">{item?.name}</p>
              //       <p className="acctext">
              //         {convertToTitleCase(item?.role)}
              //       </p>
              //     </div>
              //   </Accordion.Header>
              //   <Accordion.Body>
              //     <div className="acctexts">
              //       <h6 className="textleft">Wallet Address</h6>
              //       <p className="textright">
              //         {item?.walletAddress?.slice(0, 11)}...
              //         {item?.walletAddress?.slice(
              //           item?.walletAddress?.length - 3,
              //           item?.walletAddress?.length
              //         )}
              //         <img
              //           style={{ cursor: "pointer" }}
              //           onClick={() => {
              //             navigator.clipboard.writeText(item?.walletAddress);
              //           }}
              //           src="\assets\copy.svg"
              //           alt="img"
              //           className="img-fluid"
              //         />
              //       </p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">DOP Purchased</h6>
              //       <p className="textright">{item?.totalDop} DOP</p>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Leader Earning</h6>
              //       <div className="twice-text">
              //         <h6>
              //           $
              //           {(
              //             item?.myEarningsEth * usdPrice +
              //             item?.myEarningsUsdt
              //           )?.toFixed(4)}
              //         </h6>
              //         <p>
              //           ({item?.myEarningsEth} ETH + {item?.myEarningsUsdt}{" "}
              //           USDT)
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <h6 className="textleft">Total Purchases</h6>
              //       <div className="twice-text">
              //         <h6>
              //           {(
              //             item?.totalEth * usdPrice +
              //             item?.totalUsdt
              //           )?.toFixed(4)}{" "}
              //         </h6>
              //         <p>
              //           ({item?.totalEth} ETH + {item?.totalUsdt} USDT){" "}
              //         </p>
              //       </div>
              //     </div>
              //     <div className="acctexts">
              //       <button
              //          onClick={() => {
              //           setRoutes(true);
              //           setUserDel(item);
              //         }}
              //         className="view-btn"
              //       >
              //         View Details
              //       </button>
              //     </div>
              //   </Accordion.Body>
              // </Accordion.Item>
            );
          })}
          {pages?.length > 0 ? (
            <div className="footer-content">
              <div className="left-f">{/* <h6>SHOWING 1-10 OF 145</h6> */}</div>
              <div className="right-f">
                <nav className="right-f">
                  <ul className="pagination shgdvyufgqwiufaksufhasig">
                    <li className="page-item">
                      <button
                        onClick={() => getPrevData(currentPage)}
                        className="page-link arrowssss scsdsdfefssdvsdvsd"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M10 13L5 8L10 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </li>
                    {pages?.map((item, index) => {
                      return (
                        <li key={index} className="page-item cursor-pointer">
                          <p
                            className={
                              "page-link " +
                              (index + 1 === parseInt(currentPage)
                                ? "active-pag"
                                : "")
                            }
                            onClick={() => GetAllRounds(index + 1)}
                            style={{ fontSize: "13px !important" }}
                          >
                            {index + 1}
                          </p>
                        </li>
                      );
                    })}
                    <li className="page-item">
                      <button
                        onClick={() => getNextData(currentPage)}
                        className="page-link arrowssss"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M5 13L10 8L5 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default DataTable;
