import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
const DataTable = ({
  insuranceClaimed,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  user,
  search,
  setSearch,
  setTransactions,
  setOrderDirection1,
  setOrderField1,
  handlePageClick,
}) => {
  const [copy, setCopy] = useState(false);

  const textCopiedFun = (index) => {
    setCopy(index);

    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(4);
    }
  }

  return (
    <>
      <section className="datatable insurancedatable">
        <div className="table-responsive desktopviewss">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField1("transactionTime");
                        setOrderDirection1((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Wallet Address{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Hash{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Purchase Price{" "}
                    {/* <div className="filter-btn" style={{ cursor: "pointer" }}>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Claim Price{" "}
                    {/* <div className="filter-btn" style={{ cursor: "pointer" }}>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Amount Purchased{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField1("amount");
                        setOrderDirection1((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Tokens Insured
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField1("dopPurchased");
                        setOrderDirection1((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Insurance Status
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField1("claimType");
                        setOrderDirection1((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {insuranceClaimed?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <div className="twice-text">
                        <h6>
                          {" "}
                          {moment(item?.transactionTime).format("DD/MM/YYYY")}
                        </h6>

                        <p>
                          {" "}
                          {moment(item?.transactionTime).format("hh:mm A")}
                        </p>
                      </div>
                    </td>
                    <td>
                      <p className="codepara">
                        <CopyToClipboard
                          text={item?.by}
                          onCopy={(e) => textCopiedFun(index)}
                        >
                          <p>
                            {item?.by?.slice(0, 3)}...
                            {item?.by?.slice(
                              item?.by?.length - 3,
                              item?.by?.length
                            )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              style={{ cursor: "pointer" }}
                              // className={copy === index && "copy-button "}
                            >
                              <path
                                d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                                stroke="black"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </p>
                        </CopyToClipboard>
                        {/* {copy === index && <p className="copy-text">Copied</p>} */}
                      </p>
                    </td>
                    <td>
                      <a
                        href={
                          "https://etherscan.io/tx/" + item?.transactionHash
                        }
                        target="_blank"
                      >
                        {item?.transactionHash?.slice(0, 3)}...
                        {item?.transactionHash?.slice(
                          item?.transactionHash?.length - 3,
                          item?.transactionHash?.length
                        )}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M6.66681 3.83335H5.46681C4.72007 3.83335 4.34642 3.83335 4.06121 3.97868C3.81033 4.10651 3.6065 4.31034 3.47867 4.56122C3.33334 4.84643 3.33334 5.22008 3.33334 5.96682V11.0335C3.33334 11.7802 3.33334 12.1534 3.47867 12.4386C3.6065 12.6895 3.81033 12.8937 4.06121 13.0215C4.34614 13.1667 4.71934 13.1667 5.46462 13.1667H10.5354C11.2807 13.1667 11.6533 13.1667 11.9383 13.0215C12.1892 12.8937 12.3937 12.6893 12.5215 12.4384C12.6667 12.1535 12.6667 11.7807 12.6667 11.0354V9.83335M13.3333 6.50002V3.16669M13.3333 3.16669H10M13.3333 3.16669L8.66668 7.83335"
                            stroke="black"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </td>
                    <td>0.07</td>
                    <td>0.07</td>
                    <td>
                      {formatTokenValue(item?.amount)}{" "}
                      {item?.tokenSelected == "INC" ? "" : item?.tokenSelected}
                    </td>
                    <td>{formatTokenValue(item?.dopPurchased)} INC</td>
                    <td>
                      {item?.claimType == "token" ? (
                        <a className="btn-claim-tokens">Claimed Tokens</a>
                      ) : (
                        <a className="btn-insurance">Claimed Insurance</a>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <div className="footer-content">
            <div className="left-f">
              <h6>SHOWING 1-10 OF 145</h6>
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {"<"}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {">"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>

        {insuranceClaimed?.map((item, index) => {
          return (
            <div className="mobiledesignss d-none">
              <div className="new-accordionmobile d-none">
                <div className="upper-body">
                  <div className="inner-text">
                    <h6>Date and time</h6>
                    <p> {moment(item?.transactionTime).format("DD/MM/YYYY")}</p>

                    <p> {moment(item?.transactionTime).format("hh:mm A")}</p>
                  </div>
                  <div className="inner-text">
                    <h6>Purchase Price</h6>
                    <p>0.07</p>
                  </div>
                  <div className="inner-text">
                    <h6>Claim Price</h6>
                    <p>0.07</p>
                  </div>
                  <div className="inner-text">
                    <h6>Amount Purchased</h6>
                    <p>
                      {" "}
                      {formatTokenValue(item?.amount)}{" "}
                      {item?.tokenSelected == "INC" ? "" : item?.tokenSelected}
                    </p>
                  </div>
                </div>
                <div className="bottom-body">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        More Info{" "}
                        <img
                          src="\assets\arrow-down.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-textt">
                          <p>Tokens Insured</p>
                          <h6>{formatTokenValue(item?.dopPurchased)} INC</h6>
                        </div>
                        <div className="inner-textt">
                          <p>Hash</p>
                          <h6>
                            {" "}
                            <a
                              href={
                                "https://etherscan.io/tx/" +
                                item?.transactionHash
                              }
                              target="_blank"
                            >
                              {item?.transactionHash?.slice(0, 3)}...
                              {item?.transactionHash?.slice(
                                item?.transactionHash?.length - 3,
                                item?.transactionHash?.length
                              )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M6.66681 3.83335H5.46681C4.72007 3.83335 4.34642 3.83335 4.06121 3.97868C3.81033 4.10651 3.6065 4.31034 3.47867 4.56122C3.33334 4.84643 3.33334 5.22008 3.33334 5.96682V11.0335C3.33334 11.7802 3.33334 12.1534 3.47867 12.4386C3.6065 12.6895 3.81033 12.8937 4.06121 13.0215C4.34614 13.1667 4.71934 13.1667 5.46462 13.1667H10.5354C11.2807 13.1667 11.6533 13.1667 11.9383 13.0215C12.1892 12.8937 12.3937 12.6893 12.5215 12.4384C12.6667 12.1535 12.6667 11.7807 12.6667 11.0354V9.83335M13.3333 6.50002V3.16669M13.3333 3.16669H10M13.3333 3.16669L8.66668 7.83335"
                                  stroke="black"
                                  stroke-width="1.2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </a>
                          </h6>
                        </div>
                        <div className="inner-textt">
                          <p>Wallet Address</p>
                          <p className="codepara">
                            <CopyToClipboard
                              text={item?.by}
                              onCopy={(e) => textCopiedFun(index)}
                            >
                              <p>
                                {item?.by?.slice(0, 3)}...
                                {item?.by?.slice(
                                  item?.by?.length - 3,
                                  item?.by?.length
                                )}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  style={{ cursor: "pointer" }}
                                  // className={copy === index && "copy-button "}
                                >
                                  <path
                                    d="M6.95508 7.3623L6.95508 5.26245C6.95508 4.42237 6.95508 4.00202 7.11857 3.68115C7.26238 3.39891 7.49168 3.1696 7.77393 3.02579C8.09479 2.8623 8.51515 2.8623 9.35522 2.8623L13.5552 2.8623C14.3953 2.8623 14.8151 2.8623 15.136 3.02579C15.4182 3.16961 15.6479 3.39891 15.7917 3.68115C15.9552 4.00202 15.9552 4.42206 15.9552 5.26214L15.9552 9.46216C15.9552 10.3022 15.9552 10.7223 15.7917 11.0431C15.6479 11.3254 15.418 11.5552 15.1358 11.699C14.8152 11.8623 14.3958 11.8623 13.5574 11.8623L11.4551 11.8623M6.95508 7.3623L4.85522 7.3623C4.01515 7.3623 3.59479 7.3623 3.27393 7.52579C2.99168 7.66961 2.76238 7.89891 2.61857 8.18115C2.45508 8.50202 2.45508 8.92237 2.45508 9.76245L2.45508 13.9625C2.45508 14.8025 2.45508 15.2224 2.61857 15.5432C2.76238 15.8255 2.99168 16.0552 3.27393 16.199C3.59448 16.3623 4.01432 16.3623 4.85276 16.3623L9.05774 16.3623C9.89618 16.3623 10.3154 16.3623 10.636 16.199C10.9182 16.0552 11.1479 15.8252 11.2917 15.543C11.4551 15.2224 11.4551 14.8031 11.4551 13.9646L11.4551 11.8623M6.95508 7.3623L9.05523 7.3623C9.8953 7.3623 10.3151 7.3623 10.636 7.52579C10.9182 7.66961 11.1479 7.89891 11.2917 8.18115C11.4551 8.50171 11.4551 8.92156 11.4551 9.76001L11.4551 11.8623"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </p>
                            </CopyToClipboard>
                            {/* {copy === index && <p className="copy-text">Copied</p>} */}
                          </p>
                        </div>
                        <div className="inner-textt">
                          <p>Insurance Status</p>
                          {item?.claimType == "token" ? (
                            <a className="btn-claim-tokens">Claimed Tokens</a>
                          ) : (
                            <a className="btn-insurance">Claimed Insurance</a>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default DataTable;
